import { UserCredential } from '@firebase/auth';
import * as Sentry from '@sentry/browser';
import { logEvent } from 'firebase/analytics';
import { MenuItemOrder, Order } from '../types/src';
import { analytics } from './firebase';

export function logSignin(cred: UserCredential) {
  if (analytics) {
    try {
      logEvent(analytics, 'login', {
        provider_id: cred.providerId,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

export function logErrorSignin(error: any) {
  if (analytics) {
    try {
      logEvent(analytics, 'loginfailure', {
        error_code: error.code ? error.code : '',
        provider_id: error?.credential?.providerId ? error.credential.providerId : '',
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

export function logAccountLink(cred: UserCredential) {
  if (analytics) {
    try {
      logEvent(analytics, 'account_linked', {
        provider_id: cred.providerId,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

export function logErrorAccountLink(error: any) {
  if (analytics) {
    try {
      logEvent(analytics, 'account_linked_failure', {
        error_code: error.code ? error.code : '',
        provider_id: error?.credential?.providerId ? error.credential.providerId : '',
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

// menuitem

export function logAddToCard(menuItem: MenuItemOrder, language: string) {
  if (analytics) {
    try {
      logEvent(analytics, 'add_to_card', {
        itemId: `${menuItem.uid}`,
        itemName: `${menuItem.names[language]}`,
        itemCategory: `${menuItem.category_code}`,
        quantity: `${menuItem.count}`,
        price: `${menuItem.price}`,
        value: `${menuItem.totalprice}`,
        currency: `EUR`,
        item_ingredients: `${menuItem.multi_ingredients[language]}`,
        item_extras: `${menuItem.extraItems}`,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

export function logRemoveFromCard(menuItem: MenuItemOrder, language: string) {
  if (analytics) {
    try {
      logEvent(analytics, 'remove_from_cart' as string, {
        itemId: `${menuItem.uid}`,
        itemName: `${menuItem.names[language]}`,
        itemCategory: `${menuItem.category_code}`,
        quantity: `${menuItem.count}`,
        price: `${menuItem.price}`,
        value: `${menuItem.totalprice}`,
        currency: `EUR`,
        item_ingredients: `${menuItem.multi_ingredients[language]}`,
        item_extras: `${menuItem.extraItems}`,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

export function logEcoommercePurchase(order: Order) {
  if (analytics) {
    try {
      let menuItemNameList = [];
      if (order.menuItems) {
        menuItemNameList = order.menuItems.map((item) => {
          return item.names.de;
        });
      }
      logEvent(analytics, 'ecommerce_purchase', {
        order_client_town: `${order.client_town}`,
        order_payment: `${order.payment}`,
        order_pickup: `${order.pickup}`,
        order_different_items: `${order.menuItems.length}`,
        order_item_quantity: `${order.menuItems.length}`,
        order_totalprice: `${order.totalprice}`,
        value: `${order.totalprice}`,
        currency: `EUR`,
        order_provider: `${order.providerName}`,
        order_items: menuItemNameList.join(', '),
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

export function logAddCustomPizza(menuItem: MenuItemOrder, language: string) {
  try {
    logEvent(analytics, 'add_custom_pizza_to_cart', {
      itemId: `${menuItem.uid}`,
      itemName: `${menuItem.names[language]}`,
      quantity: `${menuItem.count}`,
      price: `${menuItem.price}`,
      value: `${menuItem.totalprice}`,
      currency: 'EUR',
      item_ingredients: `${menuItem.multi_ingredients[language]}`,
      item_extras: `${menuItem.extraItems}`,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function logRemovedCustomPizza(menuItem: MenuItemOrder, language: string) {
  try {
    logEvent(analytics, 'remove_custom_pizza_from_cart', {
      itemId: `${menuItem.uid}`,
      itemName: `${menuItem.names[language]}`,
      quantity: `${menuItem.count}`,
      price: `${menuItem.price}`,
      value: `${menuItem.totalprice}`,
      currency: 'EUR',
      item_ingredients: `${menuItem.multi_ingredients[language]}`,
      item_extras: `${menuItem.extraItems}`,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
}

import { Category, LanguageMap, SupportedCategories } from '../types/src';

export function getCategoryFromCode(code: string, locale: string) {
  const category: Category = SupportedCategories[locale].find((category) => category.code === code);
  if (category && category.value) {
    return category.value;
  } else {
    return '';
  }
}

export function getCategoryAvatarFromCode(code: string, locale: string) {
  const category: Category = SupportedCategories[locale].find((category) => category.code === code);
  if (category && category.avatar) {
    return category.avatar;
  } else {
    return 'other.svg';
  }
}

export function localizedAttribute(map: LanguageMap, locale: string): string {
  return map && map[locale] ? map[locale] : null;
}

import { Timestamp } from 'firebase/firestore';
import { LanguageMap } from './menu_item';
export class Extra {
  uid?: string;
  providerId?: string;
  names: LanguageMap;
  price: number;
  category_code: string;
  is_multiple: boolean;
  date: Timestamp;
  groups: LanguageMap;
  available: boolean;
}

export class ExtraOrder extends Extra {
  count = 0;
  checked = false;

  public constructor(init?: Partial<ExtraOrder>) {
    super();
    if (init) {
      Object.assign(this, init);
    }
  }
}

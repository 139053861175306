import 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';

export interface SalesTrend {
  [time: number]: {
    timestamp: Timestamp;
    sale_value: number;
    total_sales_value: number;
  };
}

export interface Analytic {
  lastOrderCreated: Timestamp;
  lastModified: Timestamp;
  orderTotalPrice: number;
  orderTotalCount: number;
  orderAvgPrice: number;
  menuItems: AnalyticMenuitems;
  totalAccepted: number;
  totalSent: number;
  totalPending: number;
  totalCancelled: number;
  sales_trend: SalesTrend;
  source_trend: SourceTrend;
  payment_trend: PaymentTrend;

  creditTotalPrice: number;
  creditTotalCount: number;

  pdaTotalPrice: number;
  pdaTotalCount: number;
}

export interface AnalyticMenuitems {
  [id: string]: {
    names: any;
    category_code: string;
    count: any;
    // hasExtras: boolean; //whether menuitem has extras description
    // hasExtraItems: boolean; //whether has extraitems
  };
}

export interface SourceTrend {
  timestamp: Timestamp;
  source_count: {
    [source: string]: number;
  };
  source_sales: {
    [source: string]: number;
  };
}

export interface PaymentTrend {
  timestamp: Timestamp;
  payment_count: {
    [payment: string]: number;
  };
  payment_sales: {
    [payment: string]: number;
  };
}

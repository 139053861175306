import { Timestamp } from 'firebase/firestore';

import { Interval } from './interval';
import { LanguageMap } from './menu_item';

export interface ProviderDescription {
  times?: string;
  delivery_special?: string;
  delivery_locs?: string;
  description?: string;
  tagline?: string;
  language?: string;
}

export enum ProviderCategory {
  GROCERY = 'grocery',
  RESTAURANT = 'restaurant',
}

export interface Provider {
  uid: string;
  email?: string;
  name?: string;
  picURL?: string;
  open_weekdays?: string;
  // custom_category_codes?: Array<string>;
  descriptions?: Array<ProviderDescription>;
  language?: string;
  menuLanguages?: Array<string>;
  link?: string;
  website?: string;
  phone?: string;
  address?: LanguageMap;
  town?: LanguageMap;
  vat?: string;
  zip?: number;
  country?: string;
  coverURL?: string;
  seoImageURL?: string;
  only_pickup?: boolean;
  pickupAvailable?: boolean;
  deliveryAvailable?: boolean;
  localAvailable?: boolean;
  local_table_names?: { [language: string]: boolean };
  store_visible?: boolean;
  store_menu_only?: boolean;
  store_acceptOrders?: boolean;
  type?: LanguageMap;
  min_order_price?: number;
  delivery_time?: string;
  delivery_costs?: string;
  category: ProviderCategory;
  creditcard_fee: number;
  creditcard_min_price: number;
  balance?: number;
  customItemsAvailable: boolean;
  free_delivery_from?: number;
  delivery_locations?: Location[];
  ingredients_initialized?: boolean;
  lat?: number;
  lng?: number;
  messagingToken?: string;
  rank?: number;
  specials?: LanguageMap;
  tagline?: LanguageMap;
  description?: LanguageMap;
  payment_methods?: {
    cash?: boolean;
    bancomat?: boolean;
    credit?: boolean;
    paypal?: boolean;
    monni_card?: boolean;
  };
  rating: {
    avg: number;
    count: number;
    sum: number;
  };
  verified?: boolean;
  creation_date?: Timestamp | any;
  lastTerminalLogin?: Timestamp | any;
  businessHours: BusinessHours;
  features: {
    orders: boolean;
    table: boolean;
    menu?: boolean; // optional, default true
    business: boolean;
    coupon_codes: boolean;
    support: boolean;
  };
}

export interface BusinessHours {
  active?: boolean;
  [day: number]: Interval[]; // day starting at 1 to 7
}

export interface ProviderSetting {
  balance: number;
  bringmos_fee_percent: number;
  pda_fee_percent: number;
  providerId: string;
  stripeAccountId: string;
  checkout_enabled: boolean;
  stripe_customer: string;
  branding: BrandingSettings;
}

export type BrandingSettings = {
  lightTheme: {
    primaryColor: string;
  };
  darkTheme: {
    primaryColor: string;
  };
};

export interface Location {
  names?: { [language: string]: string };
  price?: number;
  WKTpositionString?: string;
}

export interface ProviderMenuPreferences {
  category_sorting: { [category_code: string]: number };
  last_modified: Timestamp;
  disable_slices_ingredients: boolean;
}

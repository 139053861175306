import { Timestamp } from 'firebase/firestore';
import { TimestampAsJson } from '..';

import { Allergic } from './allergics';
import { ExtraOrder } from './extra';
import { Ingredient } from './ingredient';
import { Interval } from './interval';

export class LanguageMap {
  [locale: string]: string;

  constructor() {
    return {
      de: '',
      it: '',
      en: '',
    };
  }
}

export class LanguageTagMap {
  [locale: string]: string[];
}

export interface MenuItem {
  available: boolean;
  availableForBusinessOrders?: string;
  uid?: string;
  providerId?: string;
  base?: boolean;
  custom?: boolean;
  count?: number;
  names: LanguageMap;

  ingredientIds?: string[]; // new ids
  initialIngredientIds?: string[]; // old ids
  multi_ingredients?: LanguageMap; // new ing desc map
  initial_ingredients?: LanguageMap; // old ing desc map

  descriptions: LanguageMap; // old desc map

  added_ingredients?: { [ingId: string]: LanguageMap };
  removed_ingredients?: { [ingId: string]: LanguageMap };

  unavailableIngredients?: {
    [ingId: string]: Ingredient;
  };
  time_limited?: TimeLimited;

  category_code: string;
  category_order: number;

  subcategories?: LanguageMap;
  price: number;
  imageUrl?: string;
  multi_tags?: LanguageTagMap;
  allergics?: Array<Allergic>;
  // allergicIds?: string[];
  date: Timestamp | TimestampAsJson;
  changeDate: Timestamp | TimestampAsJson;
  order: number;

  slices: {
    min: number;
    max: number;
    available: boolean;
  };

  combo_config: ComboConfig[];
  combo_data: {
    selection: Partial<MenuItemOrder>[];
  };
}

export interface ComboConfig {
  ids: string[];
  category_codes: string[];
  max_selection: number;
}

export interface TimeLimited {
  active: boolean;
  monthly?: {
    [day: number]: Interval[];
  };
  weekly?: {
    [day: number]: Interval[];
  };
  daily?: Interval[];
}

export interface MenuItemOrder extends MenuItem {
  count: number;
  extras?: string;
  extraItems?: ExtraOrder[];
  extraIds?: string[];
  totalprice?: number;
  slice_data?: Array<SliceData>;
}

export interface SliceData {
  from_menu_item?: PartialMenuitem;
  ingredients?: Ingredient[];
  multi_ingredients?: LanguageMap;
}

export interface PartialMenuitem {
  id: string;
  names: LanguageMap;
  descriptions: LanguageMap;
  multi_ingredients?: LanguageMap;
  imageUrl?: string;
}

export function getComparable(menuItem: Partial<MenuItemOrder>) {
  const { uid, extras, multi_ingredients, initial_ingredients, extraItems } = menuItem;
  const copy1 = {
    uid,
    extras: extras ?? '',
    multi_ingredients: multi_ingredients ?? {},
    initial_ingredients: initial_ingredients ?? {},
    extraItems: extraItems ?? [],
  };
  return Object.assign({}, copy1);
}

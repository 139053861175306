import { Timestamp } from 'firebase/firestore';

import { MenuItemOrder } from './menu_item';

export enum Context {
  OPEN = 0,
  SHIPPED = 1,
}

export interface TimestampAsJson {
  seconds: number;
  nanoseconds: number;
}

export interface Order {
  id?: string;
  providerId?: string;
  providerName?: string;
  clientId?: string;
  totalprice?: number;
  pickup?: boolean;

  client_name?: string;
  client_phone?: string;
  client_address?: string;
  client_address_extra?: string;
  client_town?: string;
  client_zip?: number;
  clientToken?: string;

  additional_content?: string;
  menuItems?: Array<MenuItemOrder>; // orderitems differ -- count, extras, description
  date?: Timestamp | TimestampAsJson;
  order_target_date?: Timestamp | TimestampAsJson;
  order_sent_date?: Timestamp | TimestampAsJson;
  status?: number;
  status_message?: string;

  payment?: string;
  delivery_price?: number;
  language?: string;
  source?: string;
  last_modified?: Timestamp | TimestampAsJson;

  local?: boolean;
  table_name?: string;
  payment_intent?: string;
  payment_checkout_id?: string; // for web checkout
  payment_account_id?: string; // vendor stripe id
  payment_last4?: string;
  payment_type?: string;
  payment_brand?: string;

  provider_image?: string;
}

import format from 'date-fns/format';
import { de, enUS, it } from 'date-fns/locale';
import { Timestamp } from 'firebase/firestore';
import { TimestampAsJson } from '../types/src';

export function jsonToTimestamp(date: TimestampAsJson): Timestamp {
  const { seconds, nanoseconds } = date;
  return new Timestamp(seconds, nanoseconds);
}

export function jsonToDate(date: TimestampAsJson): Date {
  const { seconds, nanoseconds } = date;
  return new Timestamp(seconds, nanoseconds).toDate();
}
export function localizedDate(date: Date, localeCode: string) {
  if (date) {
    switch (localeCode) {
      case 'de':
        return format(date, 'EEEE, dd. MMM yyyy, HH:mm', { locale: de });
      case 'it':
        return format(date, 'EEEE, dd. MMM yyyy, HH:mm', { locale: it });
      case 'en':
        return format(date, 'EEEE, yyyy MMM dd, HH:mm', { locale: enUS });
      default:
        return format(date, 'EEEE, dd. MMM yyyy', { locale: de });
    }
  } else {
    return '';
  }
}

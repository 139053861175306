import * as _ from 'lodash';
import { create } from 'zustand';

import { MenuItemOrder, User } from '../types/src';
import { logAddCustomPizza, logAddToCard, logRemoveFromCard } from './analytics';

export enum Type {
  DELIVERY = 'delivery',
  PICKUP = 'pickup',
  TABLE = 'table',
}

export enum Payment {
  CASH = 'cash',
  BANCOMAT = 'bancomat',
  CREDIT = 'credit',
  MONNI = 'monni_card',
}

const orderStore = create((set) => ({
  client: {},
  menuItems: [],
  subtotal: 0,
  table: undefined,
  payment: undefined,
  targetDate: undefined,
  addMenuItem: (menuItem, count, locale) =>
    set((state) => {
      const copy1 = getComparable(menuItem);
      const found = state.menuItems.find((m) => {
        const copy2 = getComparable(m);
        return _.isEqual(copy1, copy2);
      });

      logAddToCard(menuItem as MenuItemOrder, locale);

      if (menuItem.custom && menuItem.category_code === 'pizza') {
        logAddCustomPizza(menuItem as MenuItemOrder, locale);
      }

      if (found) {
        const comboItemExtras =
          menuItem.combo_data && menuItem.combo_data.selection && menuItem.combo_data.selection.length
            ? menuItem.combo_data.selection
                .map((item) => (item.extraItems ? item.extraItems : []))
                .filter((e) => !!e.length)
                .flat()
                .map((e) => (e.is_multiple && e.count > 1 ? e.count * e.price : e.price))
                .reduce((acc, price) => acc + price, 0)
            : 0;

        const itemTotal =
          (found.count + count) *
          (menuItem.price +
            (menuItem.extraItems && menuItem.extraItems.length
              ? menuItem.extraItems
                  .map((e) => ((e.is_multiple && e.count) > 1 ? e.count * e.price : e.price))
                  .reduce((acc, price) => acc + price, 0)
              : 0) +
            comboItemExtras);
        found.count = found.count + count;
        found.totalprice = itemTotal;

        const totalOrderCalc = state.menuItems.map((mI) => mI.totalprice).reduce((acc, price) => acc + price, 0);

        return {
          menuItems: state.menuItems,
          subtotal: totalOrderCalc,
        };
      } else {
        const comboItemExtras =
          menuItem.combo_data && menuItem.combo_data.selection && menuItem.combo_data.selection.length
            ? menuItem.combo_data.selection
                .map((item) => (item.extraItems ? item.extraItems : []))
                .filter((e) => !!e.length)
                .flat()
                .map((e) => (e.is_multiple && e.count > 1 ? e.count * e.price : e.price))
                .reduce((acc, price) => acc + price, 0)
            : 0;

        const calc =
          count *
          (menuItem.price +
            (menuItem.extraItems && menuItem.extraItems.length
              ? menuItem.extraItems
                  .map((e) => ((e.is_multiple && e.count) > 1 ? e.count * e.price : e.price))
                  .reduce((acc, price) => acc + price, 0)
              : 0) +
            comboItemExtras);

        return {
          menuItems: [
            ...state.menuItems,
            {
              ...menuItem,
              count: count,
              totalprice: calc,
            },
          ],
          subtotal: state.subtotal + calc,
        };
      }
    }),
  removeMenuItemIndex: (index: number, locale) =>
    set((state) => {
      const menuItems = state.menuItems;
      menuItems.splice(index, 1);

      if (menuItems[index]) {
        logRemoveFromCard(menuItems[index] as MenuItemOrder, locale);

        if (menuItems[index].custom && menuItems[index].category_code === 'pizza') {
          logAddCustomPizza(menuItems[index] as MenuItemOrder, locale);
        }
      }

      let totalprice = 0;
      if (menuItems && menuItems.length) {
        totalprice = menuItems
          .map((item) => {
            const menuItemPrice = item.price * item.count;
            const extraPrice =
              item.extraItems && item.extraItems.length
                ? item.extraItems.map((e) => e.price).reduce((acc, price) => acc + price, 0)
                : 0;
            return menuItemPrice + extraPrice;
          })
          .reduce((acc, price) => acc + price, 0);
      }

      return {
        menuItems: menuItems,
        subtotal: totalprice,
      };
    }),
  setMenuItemCount: (index, newCount) =>
    set((state) => {
      const menuItems = Object.assign([], state.menuItems);
      if (index > -1 && menuItems[index]) {
        menuItems[index].count = newCount;
        menuItems[index].totalprice =
          newCount * menuItems[index].price +
          (menuItems[index].extraItems && menuItems[index].extraItems.length
            ? menuItems[index].extraItems.map((e) => e.price).reduce((acc, price) => acc + price, 0)
            : 0);

        // const totalprice = Object.assign([], menuItems).map((item) => {
        //     const menuItemPrice = item.price * item.count;
        //     const extraPrice = ((item.extras && item.extras.length) ? item.extras.map(e => e.price).reduce((acc, price) => acc + price, 0) : 0);
        //     return (menuItemPrice + extraPrice);
        // }).reduce((acc, price) => acc + price, 0);

        return {
          menuItems: Object.assign([], menuItems),
          // subtotal: totalprice
        };
      }
    }),
  setDeliveryPrice: (price) =>
    set(() => ({
      deliveryPrice: price,
    })),
  setPayment: (paymentToSet: Payment) =>
    set(() => ({
      payment: paymentToSet,
    })),
  setClient: (client: Partial<User>) =>
    set(() => ({
      client: client,
    })),
  setTargetDate: (date: Date) =>
    set(() => ({
      targetDate: date,
    })),
  clear: () =>
    set(() => ({
      client: {},
      menuItems: [],
      subtotal: 0,
      deliveryprice: 0,
      type: undefined,
      table: undefined,
      payment: undefined,
      targetDate: undefined,
    })),
}));

export function getComparable(menuItem: Partial<MenuItemOrder>) {
  const { uid, extras, slice_data, combo_data, multi_ingredients, initial_ingredients, extraItems } = menuItem;
  const copy1 = Object.assign({}, {
    uid,
    extras: extras ?? '',
    multi_ingredients: multi_ingredients ?? {},
    initial_ingredients: initial_ingredients ?? {},
    extraItems: extraItems ?? [],
    combo_data: combo_data ?? {},
    slice_data: slice_data ?? [],
  } as Partial<MenuItemOrder>);
  return copy1;
}

export default orderStore;

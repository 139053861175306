export interface Allergic {
  value?: string;
  code?: string;
  enabled?: boolean;
  picUrl?: string;
  description?: string;
  index?: number;
  letter?: string;
}

export class SupportedAllergics {
  public static de: Allergic[] = [
    {
      value: 'Gluten',
      code: 'gluten',
      enabled: false,
      description: '',
      index: 0,
      letter: 'A',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_gluten.png?alt=media&token=2f6b79ef-a367-4b85-8dde-1a76624d8474',
    },
    {
      value: 'Krustentiere',
      code: 'crustacean',
      enabled: false,
      description: '',
      index: 1,
      letter: 'B',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/' +
        'bringmos-10a.appspot.com/o/allergics%2Fallergic_crustacean.png?alt=media&token=d51093e7-74e8-4410-acf8-80b1e4e97c2c',
    },

    {
      value: 'Ei',
      code: 'egg',
      enabled: false,
      description: '',
      index: 2,
      letter: 'C',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_egg.png?alt=media&token=46ef668f-82eb-4645-89fb-16dee5095b38',
    },

    {
      value: 'Fisch',
      code: 'fish',
      enabled: false,
      description: '',
      index: 3,
      letter: 'D',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_fish.png?alt=media&token=4594e8a0-5443-4fd6-86a5-4e3eaaecdd79',
    },

    {
      value: 'Erdnüsse',
      code: 'peanuts',
      enabled: false,
      description: '',
      index: 4,
      letter: 'E',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_peanuts.png?alt=media&token=54804bc5-982a-44cd-b3e0-4c4ed96bccbd',
    },

    {
      value: 'Soya',
      code: 'soya',
      enabled: false,
      description: '',
      index: 5,
      letter: 'F',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_soya.png?alt=media&token=88f69bd2-9b79-453d-9b87-bdad9c8f8aa9',
    },

    {
      value: 'Milch',
      code: 'milk',
      enabled: false,
      description: '',
      index: 6,
      letter: 'G',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_milk.png?alt=media&token=41828c44-e6ed-4261-a15c-7e3c8d0b09a3',
    },

    {
      value: 'Nüsse',
      code: 'nuts',
      enabled: false,
      description: '',
      index: 7,
      letter: 'H',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_nuts.png?alt=media&token=fa54b791-ca63-428e-bfe7-f70d0b12117e',
    },

    {
      value: 'Sellerie',
      code: 'celery',
      enabled: false,
      description: '',
      index: 8,
      letter: 'M',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_cellery.png?alt=media&token=16bdc996-6b9c-47e6-8981-71a665fb2fce',
    },

    {
      value: 'Senf',
      code: 'mustard',
      enabled: false,
      description: '',
      index: 9,
      letter: 'N',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_mustard.png?alt=media&token=1693950e-f010-424b-8b1d-d06f3aea5c69',
    },

    {
      value: 'Sesam',
      code: 'sesame',
      enabled: false,
      description: '',
      index: 10,
      letter: 'L',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_sesame.png?alt=media&token=7f8369f3-ad1d-4e72-9b1d-ebd487a53781',
    },

    {
      value: 'Sulfat',
      code: 'sulphite',
      enabled: false,
      description: '',
      index: 11,
      letter: 'O',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_sulphite.png?alt=media&token=abf0603e-853b-4bc6-9eaf-d0c7585c2a29',
    },

    {
      value: 'Lupine',
      code: 'lupins',
      enabled: false,
      description: '',
      index: 12,
      letter: 'P',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_lupins.png?alt=media&token=6e7dd7bd-4bb3-4ecb-84cf-20315346e8c1',
    },

    {
      value: 'Muscheln',
      code: 'shellfisch',
      enabled: false,
      description: '',
      index: 13,
      letter: 'L',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.' +
        'appspot.com/o/allergics%2Fallergic_shellfish.png?alt=media&token=b51e8bb9-9604-4720-a4dc-cc036c02b6f6',
    },

    {
      value: 'Fleisch',
      code: 'meat',
      enabled: false,
      description: '',
      index: 14,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_meat.png?alt=media&token=51456ea2-3568-42e9-9d1a-8d8be4a0de1c',
    },
    {
      value: 'Schwein',
      code: 'pork',
      enabled: false,
      description: '',
      index: 15,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_pork_meat.png?alt=media&token=3452f14d-6eb6-485e-935e-01e75c049396',
    },
    {
      value: 'Getreide',
      code: 'corn',
      enabled: false,
      description: '',
      index: 16,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_corn.png?alt=media&token=3311bc09-0780-4472-a14a-c9ff89d02b76',
    },
    {
      value: 'Pilze',
      code: 'mushroom',
      enabled: false,
      description: '',
      index: 17,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_mushroom.png?alt=media&token=0ee80d86-6cfc-4879-aee4-a08f1b3a2813',
    },
    {
      value: 'Zucker',
      code: 'sugar',
      enabled: false,
      description: '',
      index: 18,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_sugar.png?alt=media&token=a8f109aa-f026-44e1-8154-b1c961b9abc5',
    },
    {
      value: 'Alkohol',
      code: 'alcohol',
      enabled: false,
      description: '',
      index: 19,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_alcohol.png?alt=media&token=cb9b3134-ec1d-408b-9fb6-4a1029c424e0',
    },
  ];

  public static it: Allergic[] = [
    {
      value: 'Gluten',
      code: 'gluten',
      enabled: false,
      description: '',
      index: 0,
      letter: 'A',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_gluten.png?alt=media&token=2f6b79ef-a367-4b85-8dde-1a76624d8474',
    },

    {
      value: 'Krustentiere',
      code: 'crustacean',
      enabled: false,
      description: '',
      index: 1,
      letter: 'B',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/' +
        'bringmos-10a.appspot.com/o/allergics%2Fallergic_crustacean.png?alt=media&token=d51093e7-74e8-4410-acf8-80b1e4e97c2c',
    },

    {
      value: 'Ei',
      code: 'egg',
      enabled: false,
      description: '',
      index: 2,
      letter: 'C',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_egg.png?alt=media&token=46ef668f-82eb-4645-89fb-16dee5095b38',
    },

    {
      value: 'Fisch',
      code: 'fish',
      enabled: false,
      description: '',
      index: 3,
      letter: 'D',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_fish.png?alt=media&token=4594e8a0-5443-4fd6-86a5-4e3eaaecdd79',
    },

    {
      value: 'Erdnüsse',
      code: 'peanuts',
      enabled: false,
      description: '',
      index: 4,
      letter: 'E',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_peanuts.png?alt=media&token=54804bc5-982a-44cd-b3e0-4c4ed96bccbd',
    },

    {
      value: 'Soya',
      code: 'soya',
      enabled: false,
      description: '',
      index: 5,
      letter: 'F',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_soya.png?alt=media&token=88f69bd2-9b79-453d-9b87-bdad9c8f8aa9',
    },

    {
      value: 'Milch',
      code: 'milk',
      enabled: false,
      description: '',
      index: 6,
      letter: 'G',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_milk.png?alt=media&token=41828c44-e6ed-4261-a15c-7e3c8d0b09a3',
    },

    {
      value: 'Nüsse',
      code: 'nuts',
      enabled: false,
      description: '',
      index: 7,
      letter: 'H',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_nuts.png?alt=media&token=fa54b791-ca63-428e-bfe7-f70d0b12117e',
    },

    {
      value: 'Sellerie',
      code: 'celery',
      enabled: false,
      description: '',
      index: 8,
      letter: 'M',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_cellery.png?alt=media&token=16bdc996-6b9c-47e6-8981-71a665fb2fce',
    },

    {
      value: 'Senf',
      code: 'mustard',
      enabled: false,
      description: '',
      index: 9,
      letter: 'N',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_mustard.png?alt=media&token=1693950e-f010-424b-8b1d-d06f3aea5c69',
    },

    {
      value: 'Sesam',
      code: 'sesame',
      enabled: false,
      description: '',
      index: 10,
      letter: 'L',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_sesame.png?alt=media&token=7f8369f3-ad1d-4e72-9b1d-ebd487a53781',
    },

    {
      value: 'Sulfat',
      code: 'sulphite',
      enabled: false,
      description: '',
      index: 11,
      letter: 'O',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_sulphite.png?alt=media&token=abf0603e-853b-4bc6-9eaf-d0c7585c2a29',
    },

    {
      value: 'Lupine',
      code: 'lupins',
      enabled: false,
      description: '',
      index: 12,
      letter: 'P',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_lupins.png?alt=media&token=6e7dd7bd-4bb3-4ecb-84cf-20315346e8c1',
    },

    {
      value: 'Muscheln',
      code: 'shellfisch',
      enabled: false,
      description: '',
      index: 13,
      letter: 'L',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.' +
        'appspot.com/o/allergics%2Fallergic_shellfish.png?alt=media&token=b51e8bb9-9604-4720-a4dc-cc036c02b6f6',
    },

    {
      value: 'Fleisch',
      code: 'meat',
      enabled: false,
      description: '',
      index: 14,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_meat.png?alt=media&token=51456ea2-3568-42e9-9d1a-8d8be4a0de1c',
    },
    {
      value: 'Schwein',
      code: 'pork',
      enabled: false,
      description: '',
      index: 15,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_pork_meat.png?alt=media&token=3452f14d-6eb6-485e-935e-01e75c049396',
    },
    {
      value: 'Getreide',
      code: 'corn',
      enabled: false,
      description: '',
      index: 16,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_corn.png?alt=media&token=3311bc09-0780-4472-a14a-c9ff89d02b76',
    },
    {
      value: 'Pilze',
      code: 'mushroom',
      enabled: false,
      description: '',
      index: 17,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_mushroom.png?alt=media&token=0ee80d86-6cfc-4879-aee4-a08f1b3a2813',
    },
    {
      value: 'Zucker',
      code: 'sugar',
      enabled: false,
      description: '',
      index: 18,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_sugar.png?alt=media&token=a8f109aa-f026-44e1-8154-b1c961b9abc5',
    },
    {
      value: 'Alkohol',
      code: 'alcohol',
      enabled: false,
      description: '',
      index: 19,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_alcohol.png?alt=media&token=cb9b3134-ec1d-408b-9fb6-4a1029c424e0',
    },
  ];

  public static en: Allergic[] = [
    {
      value: 'Gluten',
      code: 'gluten',
      enabled: false,
      description: '',
      index: 0,
      letter: 'A',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_gluten.png?alt=media&token=2f6b79ef-a367-4b85-8dde-1a76624d8474',
    },
    {
      value: 'Krustentiere',
      code: 'crustacean',
      enabled: false,
      description: '',
      index: 1,
      letter: 'B',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/' +
        'bringmos-10a.appspot.com/o/allergics%2Fallergic_crustacean.png?alt=media&token=d51093e7-74e8-4410-acf8-80b1e4e97c2c',
    },

    {
      value: 'Ei',
      code: 'egg',
      enabled: false,
      description: '',
      index: 2,
      letter: 'C',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_egg.png?alt=media&token=46ef668f-82eb-4645-89fb-16dee5095b38',
    },

    {
      value: 'Fisch',
      code: 'fish',
      enabled: false,
      description: '',
      index: 3,
      letter: 'D',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_fish.png?alt=media&token=4594e8a0-5443-4fd6-86a5-4e3eaaecdd79',
    },

    {
      value: 'Erdnüsse',
      code: 'peanuts',
      enabled: false,
      description: '',
      index: 4,
      letter: 'E',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_peanuts.png?alt=media&token=54804bc5-982a-44cd-b3e0-4c4ed96bccbd',
    },

    {
      value: 'Soya',
      code: 'soya',
      enabled: false,
      description: '',
      index: 5,
      letter: 'F',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_soya.png?alt=media&token=88f69bd2-9b79-453d-9b87-bdad9c8f8aa9',
    },

    {
      value: 'Milch',
      code: 'milk',
      enabled: false,
      description: '',
      index: 6,
      letter: 'G',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_milk.png?alt=media&token=41828c44-e6ed-4261-a15c-7e3c8d0b09a3',
    },

    {
      value: 'Nüsse',
      code: 'nuts',
      enabled: false,
      description: '',
      index: 7,
      letter: 'H',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_nuts.png?alt=media&token=fa54b791-ca63-428e-bfe7-f70d0b12117e',
    },

    {
      value: 'Sellerie',
      code: 'celery',
      enabled: false,
      description: '',
      index: 8,
      letter: 'M',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_cellery.png?alt=media&token=16bdc996-6b9c-47e6-8981-71a665fb2fce',
    },

    {
      value: 'Senf',
      code: 'mustard',
      enabled: false,
      description: '',
      index: 9,
      letter: 'N',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_mustard.png?alt=media&token=1693950e-f010-424b-8b1d-d06f3aea5c69',
    },

    {
      value: 'Sesam',
      code: 'sesame',
      enabled: false,
      description: '',
      index: 10,
      letter: 'L',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_sesame.png?alt=media&token=7f8369f3-ad1d-4e72-9b1d-ebd487a53781',
    },

    {
      value: 'Sulfat',
      code: 'sulphite',
      enabled: false,
      description: '',
      index: 11,
      letter: 'O',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_sulphite.png?alt=media&token=abf0603e-853b-4bc6-9eaf-d0c7585c2a29',
    },

    {
      value: 'Lupine',
      code: 'lupins',
      enabled: false,
      description: '',
      index: 12,
      letter: 'P',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_lupins.png?alt=media&token=6e7dd7bd-4bb3-4ecb-84cf-20315346e8c1',
    },

    {
      value: 'Muscheln',
      code: 'shellfisch',
      enabled: false,
      description: '',
      index: 13,
      letter: 'L',
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.' +
        'appspot.com/o/allergics%2Fallergic_shellfish.png?alt=media&token=b51e8bb9-9604-4720-a4dc-cc036c02b6f6',
    },

    {
      value: 'Fleisch',
      code: 'meat',
      enabled: false,
      description: '',
      index: 14,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_meat.png?alt=media&token=51456ea2-3568-42e9-9d1a-8d8be4a0de1c',
    },
    {
      value: 'Schwein',
      code: 'pork',
      enabled: false,
      description: '',
      index: 15,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_pork_meat.png?alt=media&token=3452f14d-6eb6-485e-935e-01e75c049396',
    },
    {
      value: 'Getreide',
      code: 'corn',
      enabled: false,
      description: '',
      index: 16,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_corn.png?alt=media&token=3311bc09-0780-4472-a14a-c9ff89d02b76',
    },
    {
      value: 'Pilze',
      code: 'mushroom',
      enabled: false,
      description: '',
      index: 17,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_mushroom.png?alt=media&token=0ee80d86-6cfc-4879-aee4-a08f1b3a2813',
    },
    {
      value: 'Zucker',
      code: 'sugar',
      enabled: false,
      description: '',
      index: 18,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_sugar.png?alt=media&token=a8f109aa-f026-44e1-8154-b1c961b9abc5',
    },
    {
      value: 'Alkohol',
      code: 'alcohol',
      enabled: false,
      description: '',
      index: 19,
      picUrl:
        'https://firebasestorage.googleapis.com/v0/b/bringmos-10a.appspot.com' +
        '/o/allergics%2Fallergic_alcohol.png?alt=media&token=cb9b3134-ec1d-408b-9fb6-4a1029c424e0',
    },
  ];
}
